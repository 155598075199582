import * as requestFromServer from "./Crud";
import {AccountSlice, callTypes} from "./Slice";

const {actions} = AccountSlice;


export const fetchAllData = (type, queryParams) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDatas(type, queryParams)
    .then(response => {
      var res = response.data;
      if (!res.error) {
        if (type === 'accounts_count') {
          dispatch(actions.allIncidentCount(res.data));
        } else if (type === 'agents') {
          dispatch(actions.accounts(res.data.data));
          dispatch(actions.accountsMeta(res.data.meta));
        } else if (type === 'single') {
          dispatch(actions.dataFetched(res.data));
        }
      } else {
        dispatch(actions.catchError({ error: res.error, callType: callTypes.action }));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Datas";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchData = (id) => dispatch => {
  return dispatch(actions.dataFetched(id));
};

export const deleteData = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteData(id)
    .then(response => {
      dispatch(actions.DataDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const addData = (type, DataForCreation) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addNewData(type, DataForCreation)
    .then(response => {
      var res = response.data;
      // console.log(res);
      if (!res.error) {
        // dispatch(actions.dataCreated(res.success));
        if (type === 'agent') {
          return res;
        }
      } else {
        dispatch(actions.catchError({ error: res.error, callType: callTypes.action }));
      }
    })
    .catch(error => {
      var message = error.response.data.message;
      var status_code = error.response.status;
          
      // error.clientMessage = "Can't create Data";
      // console.log("ERROR is", message, status_code)
      dispatch(actions.catchError({ error: message, callType: callTypes.action }));
    });
};

export const updateData = Data => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateData(Data)
    .then(() => {
      dispatch(actions.DataUpdated({ Data }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Data";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDatasStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDatas(ids, status)
    .then(() => {
      dispatch(actions.DatasStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update Datas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDatas = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDatas(ids)
    .then(() => {
      dispatch(actions.DatasDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete Datas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
