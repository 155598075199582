import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
// import {DashboardPage} from "./pages/DashboardPage";
import { DashboardPage } from "./modules/Dashbord/pages";
import { IncidentPage } from "./modules/Incidents/pages";
import {CallCenters} from './modules/CallCenters/pages';

// const DashboardPage = lazy(() =>
//   import("./modules/Dashbord/pages")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );

// const RequestRoot = lazy(() =>
//   import("./modules/Request/pages")
// );

const AgentsRoots = lazy(() =>
  import("./modules/Agents/pages")
);


const UserRoots = lazy(() =>
  import("./modules/User/pages")
);


// const LogsRoots = lazy(() =>
//   import("./modules/Logs/pages")
// );

const SettingsRoot = lazy(() =>
  import("./modules/Settings/pages")
);

// const IncidentRoot = lazy(() =>
//   import("./modules/Incidents/pages")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/incidents"/>
                }
                <Route path="/incidents" component={IncidentPage}/>
                <Route path="/agents" component={CallCenters}/>
                <Route path="/settings" component={SettingsRoot}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
