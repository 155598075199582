import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Header } from "./header";
import { FormView } from "./form";
import Geocode from "react-geocode";
import config from "../../../../../config";
import { toast } from 'react-toastify';
import { formatPhoneNumberIntl } from 'react-phone-number-input'


Geocode.setApiKey(config.google_map_api_key);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");


export function New({ id, show, onHide }) {


  const dispatch = useDispatch();
  const { actionsLoading, categories, business, org, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading || state.alert.listLoading,
      org: state.auth.organization,
      error_ser: state.accounts.error,
      categories: state.alert.categories,
      business: state.alert.business
    }),
    shallowEqual
  );

  const phoneRef = useRef();

  
  const [image, set_image] = useState(null)
  const [image_link, set_image_link] = useState(null)
  const [error, set_error] = useState(null)
  const [address, set_address] = useState(null)

  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [load, set_load] = useState(false);
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [cat, set_category] = useState([]);
  const [bus, set_bus] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState({});
  const [phone_err, setPhoneErr] = useState('');


  useEffect(() => {
    // console.log(cat);
    if (bus.length > 0) {
      // console.log(bus)
      // if (!categories || categories.length === 0) {
        dispatch(actions.fetchAllData('categories', bus[0]._id || bus[0].id ));
      // }
    }
  }, [bus])

  useEffect(() => {
    

    if (!business || business.length === 0) {
      dispatch(actions.fetchAllData('business', ''));
    }
  }, [])

  


  function setAd(add) {
    set_address(add);
    set_load(true);


    Geocode.fromAddress(add.label).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat)
        setLng(lng)
        set_load(false);

      },
      (error) => {
        set_load(false);
        console.error(error);
      }
    );
  }

  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);



  // server request for saving customer
  const saveCustomer = (e) => {

    if (phoneNumber === "") {
      return toast.error(`Error: Please enter your phone number`);
    }
    if (phone_err) {
      return toast.error(`Error: ${phone_err}`);
    }
    
    set_error(null);

    var body = {};
    var loc = {};
    loc['latitude'] = latitude;
    loc['longitude'] = longitude;

    const metaData = {
      "car_make": make,
      "car_model": model,
      "plate_number": e.target.elements.plate_number.value,
      "reporter": e.target.elements.reporter.value,
      "car_color": e.target.elements.car_color.value,
      "more_info": e.target.elements.more_info.value ? e.target.elements.more_info.value : ''
    }

    if (!loc.longitude || !loc.latitude) {
      loc.longitude = 3.435408;
      loc.latitude = 6.453460
    }

    body['location'] = loc;
    body['victim_name'] = e.target.elements.victim_name.value;
    body['more_info'] = e.target.elements.more_info.value ? e.target.elements.more_info.value : '';
    body['business'] = bus[0].id || bus[0]._id;
    body['category'] = cat[0].id || cat[0]._id;
    // body['category'] = e.target.elements.category.value;
    // body['business'] = e.target.elements.business.value;
    body['report_channel'] = 'phone_call';
    // body['phone_number'] = e.target.elements.phone_number.value;
    body['phone_number'] = formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''); //values.tel;
    body['meta_data'] = metaData;
    body['input_address'] = address.label;
     
    // console.log(body); return;


    dispatch(actions.addData('incident', body)).then((res) => {
      // console.log(res);
      // if (res) {
        onHide()
      // } else {
      //   console.log('here', res)
      //   set_error("An error occurred while creating this incident"); return;
      // }
    }).catch((error) => {
      console.log("Errror is", error);
      var message = error.response.data.message;
      var status_code = error.response.status;
      console.log(status_code, message);
    
      set_error(error?.response?.data?.message); 
      return toast.error(`Error: ${error?.response?.data?.message}`);
      //return;
    });
  };

  return (
    <Modal
      size="lg"
      show={show} 
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Header id={id} />
      <FormView
        make={make}
        setMake={setMake}
        model={model}
        setModel={setModel}
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading || load}
        data={null}
        set_bus = {set_bus}
        bus = {bus}
        business={business}
        set_category = {set_category}
        cat = {cat}
        onHide={onHide}
        categories={categories}
        error={error}
        image_link={image_link}
        image={image}
        setAd={setAd}
        address={address}

        setPhoneErr = {setPhoneErr}
        setData = {setData}
        setPhoneNumber = {setPhoneNumber}
        refPhone = {phoneRef}
      />
    </Modal>
  );
}
