import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";
import * as actions from "../../_redux/incidents/Actions";
import {useCustomersUIContext} from "../UIContext";

export function Accept({ id, show, onHide }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.alert.actionsLoading }),
    shallowEqual
  );


  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const acceptTask = () => {
    // server request for deleting customer by id
    var body = {};
    body['danger_id'] = id;
    dispatch(actions.addData('accept', body)).then((res) => {
      // dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      // customersUIProps.setIds([]);
      // console.log(res)
      // closing delete modal
      onHide();
      dispatch(actions.fetchAllData('single', id ))
        
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Accept Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to want to accept this request?</span>
        )}
        {isLoading && <span>Accepting this request...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <a onClick={onHide} class="btn btn-text-dark font-weight-bold mr-2">Cancel</a>
          <> </>
          <button
            type="button"
            onClick={acceptTask}
            className="btn btn-primary btn-elevate"
          >
            Accept
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
