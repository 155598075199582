import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import Loader from "../../../../_metronic/layout/components/loader";
import TimeAgo from 'react-timeago'
import {  useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import Moment from "moment";
import { useParams } from 'react-router-dom';


const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
export default function ViewAgent(props) {
    const dispatch = useDispatch();

    const { id } = useParams();

    const [data, set_data] = useState([]);
   
    const { dataForView, loading, pending_danger } = useSelector(
      (state) => ({
        dataForView: state.alert.dataForView,
        pending_danger: state.alert.pending_danger,
        loading: state.alert.listLoading
      }),
      shallowEqual
    );


    function getStat(d) {
        if (d === 'pending') {
            return 'pending';
        } else {
            return `now ${d}`;
        }
    }



  
    return (<>

      

                <div>
                {loading ? 
                <Loader data = {[1,2,3]} type = {"info"}/> 
                :
                !loading && dataForView && dataForView.agent ?
                
                <div>
                <div 
                style={{
                // flexDirection: 'column', 
                borderBottom: '1px solid #F0EDFF',
                paddingBottom: 20
                // display: 'flex'
                }}
                className="d-flex align-items-center mb-10"
                >

                    <div className="symbol symbol-40 symbol-light-primary mr-5" style = {{width: '10%'}}>
                        <span className="symbol-label" 
                        style = {{borderRadius: 40}}
                        >
                            <SVG
                            src={toAbsoluteUrl(
                                "/media/svg/avatars/050-girl-26.svg"
                            )}
                            className="svg-icon-lg svg-icon-primary"
                            style = {{
                                width: 30, 
                                heght: 30,
                            }} 
                            ></SVG>
                        </span>
                    </div>

                   <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span
                            className="text-dark font-weight-bolder text-hover-primary font-size-lg"
                        >
                            {`${dataForView.agent.first_name} ${dataForView.agent.last_name}`}
                        </span>

                        <span
                                className="font-size-sm"
                                style = {{color: '#555063'}}
                            >
                                {`${dataForView.agent.phone_number}`}
                            </span>
                        
                   </div>


                
             

                </div>

           

               
                    {/* <span class="label label-lg label-inline font-weight-bold mb-7" 
                    style = {{
                        backgroundColor: '#FFE2E1',
                        width: 150
                    }}>{dataForView.agent.phone_number}</span>

                    <span
                        className="mb-7 font-size-sm"
                        style = {{color: '#555063'}}
                    >
                        {`${dataForView.agent.phone_number}`}
                    </span> */}




                    {/* <div className = {`text-center mt-10`}>
                        <a
                        className = {'btn btn-primary  font-weight-bold font-size-sm pl-10 pr-10'}
                        onClick = {() => props.history.push(`/incidents/view/${id}/assign`)}
                    >{props.actionText ? props.actionText : 'Replace agent'}</a></div> */}

                </div>
                :

                <div>
                <div className="d-flex flex-center text-center text-muted min-h-150px">
                    Nothing to see,
                    <br />
                    No agent here.
                </div>
                {/* <div className = {`text-center`}>
                        <a
                        className = {'btn btn-primary font-weight-bold font-size-sm pl-10 pr-10'}
                        onClick = {() => props.history.push(`/incidents/view/${id}/assign`)}
                    >{props.actionText ? props.actionText : 'Assign agent'}</a></div> */}
                </div>
                
                
                    }
                </div>
                

             


         


</>);
}
