import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Loader from "../../../../_metronic/layout/components/loader";
import TimeAgo from 'react-timeago'
import { useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import Moment from "moment";
import { useParams } from 'react-router-dom';

const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
};
export default function ViewList(props) {
    const dispatch = useDispatch();
    const { id } = useParams();


    const [data, set_data] = useState([]);

    const { dataForView, loading, pending_danger } = useSelector(
        (state) => ({
            dataForView: state.alert.dataForView,
            pending_danger: state.alert.pending_danger,
            loading: state.alert.listLoading
        }),
        shallowEqual
    );


    function getStat(d) {
        if (d === 'pending') {
            return 'pending';
        } else {
            return `now ${d}`;
        }
    }



    function getBut(d) {
        if (!d.category) {
                return (
                    <div style={{
                        flexDirection: 'column',
                        display: 'flex'
                    }}>
                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/process`)}
                            className="btn btn-primary btn-sm font-weight-bold mt-7"
                            style={{
                                width: 150
                            }}>Start Processing</a>
                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/cancel`)}
                            className="btn btn-text-danger font-weight-bold mt-7">Cancel</a>
                    </div>
            )
        } else {
            if (d.status === 'pending') {
                return (
                    <div style={{
                        flexDirection: 'column',
                        display: 'flex'
                    }}>
                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/process`)}
                            className="btn btn-primary btn-sm font-weight-bold mt-7"
                            style={{
                                width: 150
                            }}>Update Category</a>

                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/complete`)}
                            className="btn btn-dark btn-sm font-weight-bold  mt-7"
                            style={{
                                width: 150
                            }}>Complete Request</a>

                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/cancel`)}
                            className="btn btn-text-danger font-weight-bold mt-7 ">Cancel</a>
     
                    </div>
                )
            } else {
                return (
                    <div style={{
                        flexDirection: 'column',
                        display: 'flex'
                    }}>
                          <a
                            onClick={() => props.history.push(`/incidents/view/${id}/complete`)}
                            className="btn btn-dark btn-sm font-weight-bold  mt-7"
                            style={{
                                width: 150
                            }}>Complete Request</a>
                        <a
                            onClick={() => props.history.push(`/incidents/view/${id}/cancel`)}
                            className="btn btn-text-danger font-weight-bold mt-7">Cancel</a>

                        {/* <a
                            onClick={() => props.history.push(`/incidents/view/${id}/complete`)}
                            className="btn btn-primary btn-sm font-weight-bold mt-7"
                            style={{
                                width: 150
                            }}>Complete Request</a> */}
                    </div>
                )
            }
        }
    }



    return (<>



        <div 
        // style="overflow-y: scroll; height:400px;"
            style = {{
                overflowY: 'scroll',
                // height: 00
            }}
        >
            {loading && !dataForView ?
                <Loader data={[1, 2, 3]} type={"info"} />
                :
                !loading && !dataForView ?
                    <div>
                        <div className="d-flex flex-center text-center text-muted min-h-150px">
                            All caught up!
                            <br />
                            No data here.
                        </div>
                        <div className={`text-center text-danger font-weight-bold`}>
                            <a
                                onClick={() => props.action ? props.action() : console.log('hello')}
                            >{props.actionText ? props.actionText : 'Try Again'}</a></div>
                    </div>
                    :

                    <div>
                        <div style={{
                            flexDirection: 'column',
                            borderBottom: '1px solid #F0EDFF',
                            display: 'flex'
                        }}>
                            {dataForView.user &&
                                <span
                                    className="text-dark font-weight-bolder text-hover-primary mb-4 font-size-lg"
                                >
                                    {`${dataForView.user.first_name} ${dataForView.user.last_name} ${dataForView.business ? ` (${dataForView.business.name})` : ''}`}
                                </span>}

                            <span class="label label-lg label-inline font-weight-bold mb-5"
                                style={{
                                    backgroundColor: '#FFE2E1',
                                    width: 150
                                }}>{dataForView.category ? dataForView.category.name : 'Uncategorized'}</span>

                            <span
                                className="mb-5 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                {`${dataForView.addresses.length > 0 ? dataForView.addresses[0].formatted_address : 'No Address'}`}
                            </span>

                            <a
                                onClick={() => props.history.push(`/incidents/view/${id}/address`)}
                                className="text-primary font-weight-bold mb-5 font-size-sm">
                                <SVG className="mr-2" style={{ width: 14, height: 14, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/edit.svg')} />
                                Edit Address</a>


                            {/* <a
                                onClick={() => props.history.push(`/incidents/view/${id}/address`)}
                                className="text-primary font-weight-bold mb-5 font-size-sm">
                                <SVG className="mr-2" style={{ width: 14, height: 14, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/edit.svg')} />
                                Edit More Info / Add Resolution</a> */}

                            <span
                                className="mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                <SVG className="mr-2" style={{ width: 14, height: 14, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/clock.svg')} />
                                {Moment(dataForView.created_at).format('LLLL')}
                            </span>
                            {dataForView.user &&
                                <span
                                    className="mb-5 font-size-sm">
                                    <SVG className="mr-2" style={{ width: 14, height: 14, marginRight: 5 }} src={toAbsoluteUrl('/media/nav/cross.svg')} />
                                    {dataForView.user.phone_number}</span>}
                            {props.distance && props.eta ?
                                <span
                                    className="text-primary font-weight-bold mb-5 font-size-sm">
                                    {`Agent is ${props.distance} (${props.eta})`}</span>
                                : null}

                        </div>



                        <div style={{
                            // flexDirection: 'column', 
                            borderBottom: '1px solid #F0EDFF',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}>
                            <h3 className="text-dark font-weight-bold text-hover-primary mb-4 pt-2 font-size-lg">Partner's information</h3>

                            {props.partner &&
                            <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063' }}
                                >
                                    Partner name
                                </div>

                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063', textTransform: 'capitalize' }}
                                >
                                    {props.partner.name}
                                </div>
                            </div>
                            
                            {props.partner.user &&
                            <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063' }}
                                >
                                    Contact name:
                                </div>

                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063', textTransform: 'capitalize' }}
                                >
                                     {`${props.partner.user.first_name} ${props.partner.user.last_name}`}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063' }}
                                >
                                    Telephone Number:
                                </div>

                                <div
                                    className="font-size-sm"
                                    style={{ color: '#555063' }}
                                >
                                    {props.partner.user.phone_number}
                                </div>
                            </div>
                            </div>
                            }
                            
                            </div>
                            }


                        </div>

                        {/* <div style={{
                            // flexDirection: 'column', 
                            borderBottom: '1px solid #F0EDFF',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            display: 'flex'
                        }}>


                            <span
                                className="mt-10 mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                Contact name:
                            </span>

                            <span
                                className="mt-10 mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                Partner tosin
                            </span>

                        </div> */}

                        {/* <div style={{
                            // flexDirection: 'column', 
                            borderBottom: '1px solid #F0EDFF',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            display: 'flex'
                        }}>


                            <span
                                className="mt-10 mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                Tel:
                            </span>

                            <span
                                className="mt-10 mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                +2348079225442
                            </span>

                        </div> */}



                        <div style={{
                            // flexDirection: 'column', 
                            borderBottom: '1px solid #F0EDFF',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            display: 'flex'
                        }}>


                            <span
                                className="mt-10 mb-7 font-size-sm"
                                style={{ color: '#555063' }}
                            >
                                Incident status
                            </span>

                            <span class="label label-lg label-inline mt-10 font-weight-bold mb-7"
                                style={{
                                    backgroundColor: '#FCDF81',
                                    textTransform: 'uppercase',
                                    width: 150
                                }}>{getStat(dataForView.status)}</span>




                        </div>

                        <div style={{
                            // flexDirection: 'column', 
                            // borderBottom: '1px solid #F0EDFF',
                            justifyContent: 'center',
                            display: 'flex'
                        }}>

                            {
                                (dataForView.status === 'canceled' || dataForView.status === 'completed' || dataForView.status === 'abandoned' || dataForView.status === 'abandoned') ?

                                    <a
                                        onClick={() => props.history.goBack()}
                                        class="btn btn-primary btn-sm font-weight-bold mt-7"
                                        style={{
                                            width: 150
                                        }}>Back to Request</a>

                                    :
                                    !dataForView.responder ?
                                        <a
                                            onClick={() => props.history.push(`/incidents/view/${id}/accept`)}
                                            class="btn btn-primary btn-sm font-weight-bold mt-7"
                                            style={{
                                                width: 150
                                            }}>Accept Request</a>
                                        :
                                        getBut(dataForView)
                            }




                        </div>


                    </div>

            }
        </div>








    </>);
}
