import React, {useRef, useState} from "react";
import { Modal } from "react-bootstrap";

import {  Form, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import config from "../../../../../config";

Geocode.setApiKey(config.google_map_api_key);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");

export function FormView({
  saveCustomer,
  actionsLoading,
  categories,
  onHide,
  setAd, address, error, business
}) {
  


  return (
    <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}

            {error &&
            <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{error}</div>
                <div class="alert-close">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="ki ki-close"></i></span>
                    </button>
                </div>
            </div>}
            


              <div className="d-flex align-items-center justify-content-between">


              <Form style = {{flex: 2}} className="form form-label-right"
              id = "update-form"
              onSubmit={e => {
                e.preventDefault();
                saveCustomer(e)
                }
              }
              >
                <Form.Row>
                    <Form.Group as={Col} controlId="victim_name">
                      <label>Victim's Name</label>
                      <Form.Control required placeholder="Victim Name" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="phone_number">
                      <label>Victim's Phone Nymber</label>
                      <Form.Control required placeholder="+234818118181" />
                  </Form.Group>
                  

                </Form.Row>

                <Form.Row>
                 
                <Form.Group as={Col} controlId="address">
                    <label>Last known address</label>
                    <GooglePlacesAutocomplete
                      apiKey={config.google_map_api_key}
                      apiOptions={{ language: 'en', region: 'NG' }} 
                      selectProps={{
                        value: address,
                        placeholder: 'Enter victim\'s last address',
                        onChange: setAd
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                 

                <Form.Row>
                  
                <Form.Group as={Col}  controlId="category">
                    <label>Select incident category</label>
                
                    <Form.Control
                        as="select"
                    >
                     {
                        categories && categories.map((ele, i) => {
                          return (
                            <option value = {ele._id} key = {i}>{ele.name}</option>
                          )
                        })
                      }
                      </Form.Control>
                  </Form.Group>


                  <Form.Group as={Col}  controlId="business">
                    <label>Organization</label>
                
                    <Form.Control
                        as="select"
                    >
                     {
                        business && business.map((ele, i) => {
                          return (
                            <option value = {ele.id} key = {i}>{ele.name}</option>
                          )
                        })
                      }
                      </Form.Control>

                      
                </Form.Group>

                </Form.Row>

                <Form.Row>
                  
                 

                  <Form.Group  as={Col} controlId="more_info">
                      <label>More Information</label>
                      <Form.Control as = {'textarea'} required placeholder="More info" />
                  </Form.Group>
                  
                </Form.Row>

              </Form>


            
              </div>
            </Modal.Body>
            <Modal.Footer>
              <a onClick={onHide} class="btn btn-text-dark font-weight-bold mr-2">Cancel</a>
              <> </>
              <button
                form='update-form'
                type="submit"
                disabled = {actionsLoading}
                // onClick={() => saveCustomer()}
                className="btn btn-primary btn-elevate"
              >
                Create Incident
              </button>
            </Modal.Footer>
       
    </> 
    
  );
}
