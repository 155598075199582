import {createSlice} from "@reduxjs/toolkit";

const initialdatasState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  dataForEdit: undefined,
  dataForView: null,
  lastError: null,
  count_data: null,
  active_danger: null,
  pending_danger: null,
  categories: null,
  business: null,
  ably: null,
  markers: []
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const AlertSlice = createSlice({
  name: "alert",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      // console.log("actionactionaction",action)
      state.error = `Error: ${action.payload.error}`;
      // state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getdataById
    dataFetched: (state, action) => {
      state.actionsLoading = false;
      state.dataForView = action.payload;
      state.listLoading = false;
      state.error = null;
    },
    businessFetched: (state, action) => {
      state.listLoading = false;
      state.business = action.payload;
      state.error = null;
    },
    catFetched: (state, action) => {
      state.listLoading = false;
      state.categories = action.payload;
      state.error = null;
    },
    markers: (state, action) => {
      state.listLoading = false;
      state.markers = action.payload;
      state.error = null;
    },
    users: (state, action) => {
      state.listLoading = false;
      state.entities = action.payload;
      state.error = null;
    },
    ably: (state, action) => {
      state.ably = action.payload;
      state.error = null;
    },
    disableLoad: (state, action) => {
      state.ably = action.payload;
      state.listLoading = false;
      state.actionsLoading = false;
      state.error = null;
    },
    // finddatas
    datasFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    countStatus: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.count_data = action.payload;
    },
    activeDanger: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.active_danger = action.payload;
    },
    pendingDanger: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.pending_danger = action.payload;
    },
    // createdata
    dataCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updatedata
    dataUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data;
        }
        return entity;
      });
    },
    // deletedata
    dataDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    dangerAnalytics: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.danger_data = action.payload
    }, 
    locationAnalytics: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.listLoading = false;
      state.location_data = action.payload
    }, 
  // deletedatas
    datasDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // datasUpdateState
    datasStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
