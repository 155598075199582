import React, { useEffect, useState, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import { Incidents } from "./main";
import { View } from "./view";
import { New } from "./new";
import { Assign } from './assign';
// import { Accept } from './accept-dialog';
import { Process } from './process';
import { Cancel } from "./cancel";
import { Address } from "./address";
import { CustomersUIProvider } from "./UIContext";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/CallCenters/Actions";
import GoogleMapReact from 'google-map-react';
import config from "../../../../config"
import { useParams } from 'react-router-dom';
import Marker from "../../../../_metronic/layout/components/marker";
import Notification from 'react-web-notification';
import useSound from 'use-sound';
import boopSfx from '../../../../sound/sound.mp3';

var Ably = require('ably');


export function CallCenters({ history }) {
  const clickRef = useRef();

  const customersUIEvents = {

  }

  var path = window.location.pathname;

  const { id } = useParams();
  // alert(id)
  const [coord, setCoord] = useState({
    lat: 6.5244,
    lng: 3.3792
  });
  const [zoom, setZoom] = useState(10);


  const dispatch = useDispatch();


  const { dataForView, _markers, user, entities } = useSelector(
    (state) => ({
      dataForView: state.alert.dataForView,
      user: state.auth.user,
      _markers: state.alert.markers,
      entities: state.alert.active_danger ? state.alert.active_danger.dangers : []
    }),
    shallowEqual
  );


  const [ignore, setIgnore] = useState(false)
  const [title, setTitle] = useState('')
  const [trigger, setTrigger] = useState(true)
  const [options, setOptions] = useState({})
  const [play] = useSound(boopSfx);


  // useEffect(() => {
  //   if(trigger){
  //     handleButtonClick()
  //   }
  // }, [trigger])

  const handleButtonClick = () => {
    if (ignore) {
      return;
    }
    // clickRef.current.click()
    setTitle('New Request')
    setOptions({
      tag: Date.now(),
      body: `${user.first_name} attend to this new request`
    })
  }


  // if (window.location.pathname === "\incidents") {
  //   dispatch(actions.fetchData(null))
  // }

  const tabs = [
    {
      "name": "Pending", "key": 'pending'
    },
    {
      "name": "Active", "key": 'active'
    }
  ]


  useEffect(() => {
    setupReal()
  }, [])

  function _onChange(data) {
    setCoord({
      lat: data.center.lat,
      lng: data.center.lng
    })
    setZoom(data.zoom);
  }


  const agentLocations = [
    {
      location: {
        latitude: 6.714704,
        longitude: 3.245368,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Monitoring Agent 1'
      },
    },
    {
      location: {
        latitude: 6.599561,
        longitude: 3.349621,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 2'
      },
    },

    {
      location: {
        latitude: 6.597436,
        longitude: 3.358361,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 3'
      },
    },

    {
      location: {
        latitude: 6.607374,
        longitude: 3.334479,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 4'
      },
    },

    {
      location: {
        latitude: 6.603827,
        longitude: 3.326838,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 5'
      },
    },

    {
      location: {
        latitude: 6.632207,
        longitude: 3.360271,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Live Agent 6'
      },
    },

    {
      location: {
        latitude: 6.551519,
        longitude: 3.275153,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 7'
      },
    },

    {
      location: {
        latitude: 6.449484,
        longitude: 3.334829,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 8'
      },
    },

    {
      location: {
        latitude: 6.438634,
        longitude: 3.390789,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 9'
      },
    },

    {
      location: {
        latitude: 6.461841,
        longitude: 3.421255,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 10'
      },
    },

    {
      location: {
        latitude: 6.464116,
        longitude: 3.455117,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 11A'
      },
    },

    {
      location: {
        latitude: 6.463815,
        longitude: 3.447824,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 11B'
      },
    },

    {
      location: {
        latitude: 6.460498,
        longitude: 3.423363,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 12'
      },
    },

    {
      location: {
        latitude: 6.435916,
        longitude: 3.464529,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 13'
      },
    },

    {
      location: {
        latitude: 6.427537,
        longitude: 3.460154,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Monitoring Agent 14'
      },
    },

    {
      location: {
        latitude: 6.433125,
        longitude: 3.525274,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Live Agent 14'
      },
    },

    {
      location: {
        latitude: 6.457590,
        longitude: 3.589083,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 15'
      },
    },

    {
      location: {
        latitude: 6.502444,
        longitude: 3.679278,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 16'
      },
    },
    {
      location: {
        latitude: 6.723962,
        longitude: 3.749234,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 18'
      },
    },

    {
      location: {
        latitude: 6.941704,
        longitude: 3.511472,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 17'
      },
    },

    {
      location: {
        latitude: 6.487737,
        longitude: 2.937153,
      },
      title: 'Monitoring Agent',
      status: 'demo',
      category: {
        name: 'Agent 19'
      },
    },

   
  ]



  function navTo(ele) {
    setCoord({
      lat: ele.location.latitude,
      lng: ele.location.longitude
    })
    setTimeout(function () {
      setZoom(18);
    }, 1000);

    history.push(`/incidents/view/${ele._id}`);
    dispatch(actions.fetchData(ele))
  }


  useEffect(() => {
    if (dataForView) {
      // alert('in', id);
      setTimeout(function () {
        setCoord({
          lat: dataForView.location.latitude,
          lng: dataForView.location.longitude
        })
      }, 500);

      setTimeout(function () {
        setZoom(25);
      }, 800);
    } else {
      // alert('out', id);
      setTimeout(function () {
        setZoom(11);
      }, 500);
    }
  }, [dataForView])


  function setupReal() {
    var client = new Ably.Realtime(config.ABLY_KEY);
    dispatch(actions.sendAb(client));
    client.connection.on('connected', function () {
      var channel = client.channels.get(config.ABLY_CHANNEL_NAME);
      channel.subscribe(user.user_id, function (message) {
        // console.log("ABLY!!!", message.name, message.data)
        dispatch(actions.fetchAllData('active_danger', user.user_id));
        handleButtonClick();
      });
    });
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />

      <Notification
        ignore={ignore}
        timeout={5000}
        title={title}
        options={options}
      />
      <a ref={clickRef}
        onClick={() => play()}
        href="javascript:;"
        style={{ display: 'none' }}
      ></a>

      <div style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginRight: -25,
        marginLeft: -25,
        marginTop: -25,
      }}>

        <div style={{
          flex: 5,
          display: 'flex',
          backgroundColor: 'grey'
        }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.google_map_api_key, libraries: ['places'] }}
            defaultCenter={{
              lat: 6.5244,
              lng: 3.3792
            }}

            defaultZoom={11}
            center={coord}
            zoom={zoom}
            onChange={_onChange}
            options={{
              styles: config.mapStyle,
            }}
            id="map"
          >
            {agentLocations.map((ele, i) => (
               <div key={i}
               lat={ele.location.latitude}
               lng={ele.location.longitude}
               text={ele.category ? ele.category.name : 'Danger'}
             >
               <Marker
                 onClick={() => navTo(ele)}
                 data={ele}
                 id={id ? id : 'none'}
               />
             </div>
            ))}

          </GoogleMapReact>

        </div>




      </div>

    </CustomersUIProvider>
  );
}
