import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { DashboardSlice } from "../app/modules/Dashbord/_redux/dashboard/Slice";
import { AlertSlice } from "../app/modules/Incidents/_redux/incidents/Slice";
import {AccountSlice} from "../app/modules/Agents/_redux/accounts/Slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: DashboardSlice.reducer,
  alert: AlertSlice.reducer,
  accounts: AccountSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
