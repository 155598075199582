import React, { useState } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import config from "../../../../../config";
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { isValidPhoneNumber } from 'react-phone-number-input'


import carModels from '../../../car-models.json';
import carBrands from '../../../car-brands.json';

import { Typeahead } from 'react-bootstrap-typeahead';


const basicColors = [
  'Black', 'Grey', 'White', 'Ash', 'Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'Purple',
]

Geocode.setApiKey(config.google_map_api_key);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");

export function FormView({
  saveCustomer,
  actionsLoading,
  categories, cat, set_category,
  bus, set_bus,
  onHide,
  setAd, address, error, business,
  make, setMake, model, setModel,
  setPhoneErr,setData,setPhoneNumber,refPhone
}) {
  const [models, setModels] = useState();

  const handleChangeCar = (selectedOption) => {
    setMake(selectedOption.value);
    carModels.map((car, i) => {
      if (car.brand.toLowerCase() == selectedOption.value.toLowerCase()) {
        setModels(model => car.models);
      }
    });
  };




  const handleChangeModel = (mode) => {
    setModel(mode);
  }

  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}

        {/* <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          This is a primary alert—check it out!
        </div> */}

        {error &&
          <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">{error}</div>
            <div class="alert-close">
              <button type="button" onClick={() => console.log('')} class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="ki ki-close"></i></span>
              </button>
            </div>
          </div>}



        <div className="d-flex align-items-center justify-content-between">


          <Form style={{ flex: 2 }} className="form form-label-right"
            id="update-form"
            onSubmit={e => {
              e.preventDefault();
              saveCustomer(e)
            }
            }
          >
            <Form.Row>
              <Form.Group as={Col} controlId="victim_name">
                <label>Victim's Name</label>
                <Form.Control required placeholder="Victim Name" />
              </Form.Group>

              {/* <Form.Group as={Col} controlId="phone_number">
                <label>Victim's Phone Nymber</label>
                <Form.Control required placeholder="+234818118181" />
              </Form.Group> */}

              <Form.Group as={Col} controlId="phone_number">
                  <label>Victim's Phone Number</label>
                  <IntlTelInput
                        ref = {refPhone}
                        preferredCountries={['ng', 'za', "us", "gb", 'gh']}
                        inputClassName={`form-control`}
                        style = {{
                            width: '100%'
                        }}
                        format = {true}
                        onPhoneNumberFocus = {(isValid, number, obj) => {
                          var num = `+${obj?.dialCode}${number}`;
                          var isValidR = isValidPhoneNumber(num)
                          // console.log(isValidR)
                          setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                        }}
                        onPhoneNumberBlur= {(isValid, number, obj) => {
                          var num = `+${obj?.dialCode}${number}`;
                          var isValidR = isValidPhoneNumber(num)
                          // console.log(isValidR)
                          setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                        }}
                        onPhoneNumberChange = {(isValid, number, obj) => {
                            var num = `+${obj?.dialCode}${number}`;
                            var isValidR = isValidPhoneNumber(num)
                            setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                            setPhoneNumber(number)
                            setData(obj)
                        }}
                        containerClassName="intl-tel-input"
                    />

              </Form.Group>
            </Form.Row>

            <Form.Row>

              <Form.Group as={Col} controlId="address">
                <label>Last known address</label>
                <GooglePlacesAutocomplete
                  apiKey={config.google_map_api_key}
                  apiOptions={{ language: 'en', region: 'NG' }}
                  selectProps={{
                    value: address,
                    placeholder: 'Enter victim\'s last address',
                    onChange: setAd
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                        country: ['NG'],
                    }
                    // types: ['postal_code'],
                  }}
                  
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>

         

            <Form.Group as={Col} controlId="business">
                <Form.Label>Organization</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={set_bus}
                  options={business ? business : []}
                  disabled= {actionsLoading}
                  isLoading={actionsLoading}
                  placeholder="Choose business..."
                  selected={bus}
                />
              </Form.Group>


              <Form.Group as={Col} controlId="category">
                <Form.Label>Select incident category</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={set_category}
                  options={categories ? categories : []}
                  disabled= {actionsLoading}
                  isLoading={actionsLoading}
                  placeholder="Choose a category..."
                  selected={cat}
                />
              </Form.Group>

              

            </Form.Row>
            <div className="car-form">
              <Form.Row>
                <Form.Group as={Col} controlId="car_make">
                  <label>Select Car Type</label>
                  <Select
                    isSearchable
                    id="car_make"
                    defaultValue={make}
                    onChange={(car) => handleChangeCar(car)}
                    className="basic-single"
                    classNamePrefix="select"
                    name="Select Car Type"
                    options={carBrands}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="car_model">
                  <label>Select Car Model</label>
                  <Select
                    isSearchable
                    id="car_model"
                    defaultValue={model}
                    value={models && models.filter(function (option) {
                      return option === model;
                    })}
                    onChange={handleChangeModel}
                    className="basic-single"
                    classNamePrefix="select"
                    name="Select Car's Model"
                    options={models}
                    getOptionLabel={(option) => option}
                    getOptionValue={(option) => option}
                  />
                </Form.Group>

              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="plate_number">
                  <label>Car's plate number</label>
                  <Form.Control placeholder="Plate number" type="text" />
                </Form.Group>

                <Form.Group as={Col} controlId="reporter">
                  <label>User type</label>

                  <Form.Control as="select">
                    <option value="rider">Rider</option>
                    <option value="driver">Driver</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="car_color">
                  <label>Car's Color</label>
                  <Form.Control as="select">
                    {basicColors.map((color, index) => (
                      <option value={color} key={index}>{color}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </div>
            <Form.Row>



              <Form.Group as={Col} controlId="more_info">
                <label>More Information</label>
                <Form.Control as={'textarea'} placeholder="More info" />
              </Form.Group>

            </Form.Row>

          </Form>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <a onClick={onHide} class="btn btn-text-dark font-weight-bold mr-2">Cancel</a>
        <> </>
        <button
          form='update-form'
          type="submit"
          disabled={actionsLoading}
          // onClick={() => saveCustomer()}
          className="btn btn-primary btn-elevate"
        >
          Create Incident
        </button>
      </Modal.Footer>

    </>

  );
}
