import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Header } from "./header";
import { FormView } from "./form";
import config from "../../../../../config";



export function Complete({ id, show, onHide }) {


  const dispatch = useDispatch();
  const { actionsLoading, user, categories, business, org, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading || state.alert.listLoading,
      org: state.auth.organization,
      user: state.auth.user,
      error_ser: state.accounts.error,
      categories: state.alert.categories,
      business: state.alert.business
    }),
    shallowEqual
  );

  const [error, set_error] = useState(null)


  



  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);



  // server request for saving customer
  const saveCustomer = (e) => {

    set_error(null);

    var body = {};
    body['agent_feedback'] = e.target.elements.more_info.value;
    body['responder_id'] = user.user_id
    body['danger_id'] = id;

    // console.log(body); return;
    dispatch(actions.addData('complete', body)).then((res) => {
      dispatch(actions.fetchAllData('single', id ))
      onHide()
    }).catch((error) => {
      console.log("Errror is", error);
      var message = error.response.data.message;
      var status_code = error.response.status;
      console.log(status_code, message);
    
      set_error("An error occurred while creating this incident"); return;
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Header id={id} />
      <FormView
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        data={null}
        onHide={onHide}
        error={error}
      />
    </Modal>
  );
}
