import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Header } from "./header";
import { FormView } from "./form";
import Geocode from "react-geocode";
import config from "../../../../../config";


Geocode.setApiKey(config.google_map_api_key);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");


export function Address({ id, show, onHide }) {

  
  const dispatch = useDispatch();
  const { actionsLoading, dataForView, business, org, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.alert.actionsLoading,
      org: state.auth.organization,
      error_ser: state.alert.error,
      dataForView: state.alert.dataForView,
      business: state.alert.business
    }),
    shallowEqual
  );

  const [address, set_address] = useState(null)
  const [error, set_error] = useState(null)
  
  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [load, set_load] = useState(false);

  useEffect(() => {
    if (!dataForView && id) {
      dispatch(actions.fetchAllData('single', id ))
    }
  }, [])


  useEffect(() => {
    if (address === '' && dataForView) {
      // set_address(dataForView.addresses[0].formatted_address)
      set_address(`${dataForView.addresses.length > 0 ? dataForView.addresses[0].formatted_address : 'No Address'}`)
    }
  }, [address,dataForView ])



  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);


  function setAd(add) {
    set_address(add);
    set_load(true);

    
    Geocode.fromAddress(add.label).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat)
        setLng(lng)
        set_load(false);
    
      },
      (error) => {
        set_load(false);
        console.error(error);
      }
    );
  }

  // server request for saving customer
  const saveCustomer = (e) => {
      
      if (!longitude) {
        return;
      }

      set_error(null);

      var body = {};
      var loc = {};
      loc['latitude'] = latitude;
      loc['longitude'] = longitude;
      
      body['location'] = loc;

      

      dispatch(actions.addData('update_address', body, id)).then((res) => {
        onHide()
        // dispatch(actions.fetchAllData('single', id ))
      }).catch(() => {
        set_error("An error occured while updating address");return;
      });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Header id={id} />
      <FormView
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading || load}
        data={null}
        business = {business}
        onHide={onHide}
        dataForView = {dataForView}
        error = {error}
        setAd = {setAd}
        address = {address}
      />
    </Modal>
  );
}
