import React, { useState } from "react";
import { Modal, Form, Col } from "react-bootstrap";
import Geocode from "react-geocode";
import config from "../../../../../config";
import Select from 'react-select';

import carModels from '../../../car-models.json';
import carBrands from '../../../car-brands.json';

import { Typeahead } from 'react-bootstrap-typeahead';


const basicColors = [
  'Black', 'Grey', 'White', 'Ash', 'Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet', 'Purple',
]



export function FormView({
  saveCustomer,
  actionsLoading,
  categories, cat, set_category,
  bus, set_bus,
  onHide,
  setAd, address, error, business,
  make, setMake, model, setModel,
}) {
  const [models, setModels] = useState();

  const handleChangeCar = (selectedOption) => {
    setMake(selectedOption.value);
    carModels.map((car, i) => {
      if (car.brand.toLowerCase() == selectedOption.value.toLowerCase()) {
        setModels(model => car.models);
      }
    });
  };




  const handleChangeModel = (mode) => {
    setModel(mode);
  }

  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}

        {/* <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          This is a primary alert—check it out!
        </div> */}

        {error &&
          <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">{error}</div>
            <div class="alert-close">
              <button type="button" onClick={() => console.log('')} class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"><i class="ki ki-close"></i></span>
              </button>
            </div>
          </div>}



        <div className="d-flex align-items-center justify-content-between">


          <Form style={{ flex: 2 }} className="form form-label-right"
            id="update-form"
            onSubmit={e => {
              e.preventDefault();
              saveCustomer(e)
            }
            }
          >

            <Form.Row>
              <Form.Group as={Col} controlId="more_info">
                <label>Feedback / Resolution</label>
                <Form.Control as={'textarea'} required placeholder="Provide feedback" />
              </Form.Group>

            </Form.Row>

          </Form>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <a onClick={onHide} class="btn btn-text-dark font-weight-bold mr-2">Cancel</a>
        <> </>
        <button
          form='update-form'
          type="submit"
          disabled={actionsLoading}
          // onClick={() => saveCustomer()}
          className="btn btn-primary btn-elevate"
        >
          Complete Incident
        </button>
      </Modal.Footer>

    </>

  );
}
