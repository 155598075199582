import React, { useEffect, useState, useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./loading-dialog";
import { Incidents } from "./main";
import { View } from "./view";
import { New } from "./new";
import { Assign } from './assign';
import { Accept } from './accept-dialog';
import { Process } from './process';
import { Cancel } from "./cancel";
import { Complete } from "./complete";
import { Address } from "./address";
import { CustomersUIProvider } from "./UIContext";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actions from "../_redux/incidents/Actions";
import GoogleMapReact from 'google-map-react';
import config from "../../../../config"
import { useParams } from 'react-router-dom';
import Marker from "../../../../_metronic/layout/components/marker";
import Notification from 'react-web-notification';
import useSound from 'use-sound';
import boopSfx from '../../../../sound/sound.mp3';

import { ToastContainer, toast } from 'react-toastify';


var Ably = require('ably');


export function IncidentPage({ history }) {
  const clickRef = useRef();

  const customersUIEvents = {

  }

  const notify = (message) => toast(message)


  // console.log(window.location.pathname);

  var path = window.location.pathname;

  const { id } = useParams();
  // alert(id)
  const [coord, setCoord] = useState({
    lat: 6.5244,
    lng: 3.3792
  });
  const [zoom, setZoom] = useState(10);


  const dispatch = useDispatch();


  const { dataForView, error, _markers, user, entities } = useSelector(
    (state) => ({
      dataForView: state.alert.dataForView,
      error: state.alert.error,
      user: state.auth.user,
      _markers: state.alert.markers,
      entities: state.alert.active_danger ? state.alert.active_danger.dangers : []
    }),
    shallowEqual
  );


  const [ignore, setIgnore] = useState(false)
  const [title, setTitle] = useState('')
  const [trigger, setTrigger] = useState(true)
  const [options, setOptions] = useState({})
  const [play] = useSound(boopSfx);


  // useEffect(() => {
  //   if(trigger){
  //     handleButtonClick()
  //   }
  // }, [trigger])

  const handleButtonClick = () => {
    if (ignore) {
      return; 
    }
    clickRef.current.click()
    setTitle('New Request') 
    setOptions({
      tag: Date.now(),
      body: `${user.first_name} attend to this new request`
    })
  }


  // if (window.location.pathname === "\incidents") {
  //   dispatch(actions.fetchData(null))
  // }

  const tabs = [
    {
      "name": "Pending", "key": 'pending'
    },
    {
      "name": "Active", "key": 'active'
    }
  ]


  useEffect(() => {
    setupReal()
  }, [])


  useEffect(() => {
    if (error) {
        notify(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            theme: 'light',
            type: 'error',
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}, [error])


  function _onChange(data) {
    setCoord({
      lat: data.center.lat,
      lng: data.center.lng
    })
    setZoom(data.zoom);
  }




  function navTo(ele) {
    setCoord({
      lat: ele.location.latitude,
      lng: ele.location.longitude
    })
    setTimeout(function () {
      setZoom(18);
    }, 1000);

    history.push(`/incidents/view/${ele._id}`);
    dispatch(actions.fetchData(ele))
  }


  useEffect(() => {
    if (dataForView) {
      // alert('in', id);
      setTimeout(function () {
        setCoord({
          lat: dataForView.location.latitude,
          lng: dataForView.location.longitude
        })
      }, 500);

      setTimeout(function () {
        setZoom(25);
      }, 800);
    } else {
      // alert('out', id);
      setTimeout(function () {
        setZoom(11);
      }, 500);
    }
  }, [dataForView])


  function setupReal() {
    var client = new Ably.Realtime(config.ABLY_KEY);
    dispatch(actions.sendAb(client));
    client.connection.on('connected', function () {
      var channel = client.channels.get(config.ABLY_CHANNEL_NAME);
      channel.subscribe(user.user_id, function (message) {
        // console.log("ABLY!!!", message, message.name, message.data)
        dispatch(actions.fetchAllData('active_danger', user.user_id));
        if (message.data?.responder !== user.user_id)  {
          handleButtonClick();
        } 
      });
    });
  }



//   useEffect(() => {
//     if (error) {
//         notify(error, {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             theme: 'light',
//             type: 'warning',
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//         });
//     }
// }, [error])



  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />

      <Notification
        ignore={ignore}
        timeout={5000}
        title={title}
        options={options}
      />
      <a ref={clickRef}
        onClick={() => play()}
        href="javascript:;"
        style={{ display: 'none' }}
      ></a>

      <div style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginRight: -25,
        marginLeft: -25,
        marginTop: -25,
      }}>


        <div style={{
          // flex: 2,
          width: 350,
          display: 'flex',
          flexDirection: 'column'
        }}>

          <Switch>


            <Route path="/incidents" exact>
              {({ history, match }) => (
                <Incidents
                  show={match != null}
                  history={history}
                  onHide={() => {
                    history.push("/");
                  }}
                />
              )}
            </Route>

            <Route path="/incidents/view/:id">
              {({ history, match }) => (
                <View
                  show={match != null}
                  history={history}
                  onHide={() => {
                    history.push("/");
                  }}
                />
              )}
            </Route>


          </Switch>

          <Route path="/incidents/new">
            {({ history, match }) => (
              <New
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>

          <Route path="/incidents/view/:id/address">
            {({ history, match }) => (
              <Address
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>



          <Route path="/incidents/view/:id/process">
            {({ history, match }) => (
              <Process
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>

          <Route path="/incidents/view/:id/complete">
            {({ history, match }) => (
              <Complete
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>

          <Route path="/incidents/view/:id/accept">
            {({ history, match }) => (
              <Accept
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>

          <Route path="/incidents/view/:id/cancel">
            {({ history, match }) => (
              <Cancel
                show={match != null}
                id={match && match.params.id}
                history={history}
                onHide={() => {
                  history.goBack();
                }}
              />
            )}
          </Route>






        </div>

        <div style={{
          flex: 5,
          display: 'flex',
          backgroundColor: 'grey'
        }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: config.google_map_api_key, libraries: ['places'] }}
            defaultCenter={{
              lat: 6.5244,
              lng: 3.3792
            }}

            defaultZoom={11}
            center={coord}
            zoom={zoom}
            onChange={_onChange}
            options={{
              styles: config.mapStyle,
            }}
            id="map"
          >
            {/* <div
              lat={6.707786}
              lng={3.270826}
              text={'Danger'}
            >
              <Marker
                onClick={() => navTo(6.707786, 3.270826)}
                data={{status: 'demo'}}
                id={'none'}
              />
            </div>

            <div
              lat={6.663335}
              lng={3.329258}
              text={'Rescue'}
            >
              <Marker
                onClick={() => navTo(6.663335, 3.329258)}
                data={{status: 'demo', category : {name: 'Reporting'}}}
                id={'none'}
              />
            </div>

            <div
              lat={6.645288}
              lng={3.677872}
              text={'Rescue'}
            >
              <Marker
                onClick={() => navTo(6.645288, 3.67872)}
                data={{status: 'demo', category : {name: 'Rescue'}}}
                id={'none'}
              />
            </div> */}
            {
              !dataForView ?
                entities.map((ele, i) => {
                  return (
                    <div key={i}
                      lat={ele.location.latitude}
                      lng={ele.location.longitude}
                      text={ele.category ? ele.category.name : 'Danger'}
                    >
                      <Marker
                        onClick={() => navTo(ele)}
                        data={ele}
                        id={id ? id : 'none'}
                      />
                    </div>
                  )
                })

                :

                _markers.map((ele, i) => {
                  //  console.log("dsdcascsdcasdcsdcsdcs", ele)
                  return (
                    <div key={i}
                      lat={ele.latitude}
                      lng={ele.longitude}
                      text={'ele.name'}
                    >
                      <Marker
                        onClick={() => console.log(ele)}
                        data={ele}
                        id={id ? id : 'none'}
                      />
                    </div>
                  )
                })

            }

          </GoogleMapReact>

        </div>




      </div>


      {/* <ToastContainer 
            progressClassName="toastProgress"
            bodyClassName="toastBody"
        /> */}

    </CustomersUIProvider>
  );
}
