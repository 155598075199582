import axios from "axios";
import config from "../../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(data) {
  return axios.post(URL, { data });
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'dashboard_data') {
    return axios.get(`${URL}partners/${queryParams}/dashboard-data`);
  } else if (type === 'users') {
    return axios.get(`${URL}partners/${queryParams}/users`);
  } 
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}
