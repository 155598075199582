import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../_metronic/_partials/controls";

export function Header({ id }) {
  // Customers Redux state
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading,
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <span className="text-dark font-weight-bold" style = {{fontSize: 15}}>
          Add a new incident
        </span>
      </Modal.Header>
    </>
  );
}
