/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
// import Icon from '@mui/material/Icon';
import {Link} from "react-router-dom";
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
// import ThreeDRotation from '@mui/icons-material/ThreeDRotation';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  const returnAct = (url) => {
    return checkIsActive(location, url)
        ? true
        : false;
  };


  const links = [
    // {
    //   name: 'Overview',
    //   link: '/dashboard'
    // },
    {
      name: 'Incidents',
      link: '/incidents'
    },
    // {
    //   name: 'Agents',
    //   link: '/agents'
    // },
    // {
    //   name: 'Agents',
    //   link: '/agents'
    // },
    {
      name: 'Settings',
      link: '/settings'
    }
  ]


  function getRoute(name, active) {
    if (name === 'Overview') {
      if (active) {
        return "/media/nav/dashboard_active.svg";
      } else {
        return "/media/nav/dashboard.svg";
      }
    } else if (name === 'Incidents') {
      if (active) {
        return "/media/nav/alert_active.svg";
      } else {
        return "/media/nav/alert.svg";
      }
    } else if (name === 'Agents') {
      if (active) {
        return "/media/nav/agent_active.svg";
      } else {
        return "/media/nav/agent.svg";
      }
    } else if (name === 'Settings') {
      if (active) {
        return "/media/nav/settings_active.svg";
      } else {
        return "/media/nav/settings.svg";
      }
    }
    return "/media/nav/dashboard.svg";
  }

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        
        <Link to="/incidents/new">
        <a
              type="button"
              // className="btn btn-primary btn-md font-weight-bold" //r px-9 py-4 my-3 mx-4
              className="btn btn-primary rounded-0 font-weight-bolder"
              style = {{
                marginLeft: 25,
              }}
              // onClick={customersUIProps.newAccountButtonClick}
          >
              + Create Incident
          </a>
        </Link>

          
            
        {
          links.map((ele, i) => {
            return (
              <li key = {i}
                className={`menu-item`}
                style = {{
                  marginBottom: 15,
                  marginTop: i===0 ? 10 : 0,
                  
                }}
            >
              <NavLink className="menu-link" style = {{
                backgroundColor: 'transparent'
              }} to={ele.link}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl(getRoute(ele.name, returnAct(ele.link)))}/>
              </span>
                <span className="menu-text" style = {{
                    color: returnAct(ele.link) ? '#5341C4' : '#393939',
                    fontWeight: 600,
                  }}>{ele.name}</span>
              </NavLink>
            </li>
            )
          })
        }


        </ul>
        {/* end::Menu Nav */}
      </>
  );
}
