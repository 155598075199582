import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import { Col, Form } from "react-bootstrap";
// import PhoneInput from 'react-phone-number-input/input'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { toast } from 'react-toastify';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'

import { FormControl } from "@material-ui/core";

const search = window.location.search;
const params = new URLSearchParams(search);
const emailAddress = params.get('email');

const initialValues = {
  fullname: "",
  email: emailAddress,
  gender: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const phoneRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState({});
  const [gender, setGender] = useState('');
  const [phone_err, setPhoneErr] = useState('');
  
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    last_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
    ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // gender: Yup.string()
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    // changepassword: Yup.string()
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   )
    //   .when("password", {
    //     is: (val) => (val && val.length > 0 ? true : false),
    //     then: Yup.string().oneOf(
    //       [Yup.ref("password")],
    //       "Password and Confirm Password didn't match"
    //     ),
    //   }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };


  const updateGender = (e) => {
    setGender(e.target.value);
}


  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // alert("hshs")
      enableLoading();
      // var body = {};
      var body = {};
      if (!values.email) {
          disableLoading();
          return toast.error(`Error: Please revalidate this link from your email`);
      }
      if (gender === '') {
          disableLoading();
          return toast.error(`Error: Please select a gender`);
      }
      if (phoneNumber === "") {
          disableLoading();
          return toast.error(`Error: Please enter your phone number`);
      }
      if (phone_err) {
          disableLoading();
          return toast.error(`Error: ${phone_err}`);
      }
      body['user_type'] = 'responder';
      body['email'] = values.email;
      body['first_name'] = values.first_name;
      body['last_name'] = values.last_name;
      body['password'] = values.password;
      body['gender'] = gender;
      body['country_code'] = `${data?.iso2.toUpperCase()}`; //'NG';
      body['country_phone_code'] = `+${data?.dialCode}`;
      body['phone_number'] = formatPhoneNumberIntl(`+${data?.dialCode}${phoneNumber}`).replace(/\s/g, ''); //values.tel;

      // console.log(body); return;
      register(body)
      .then(({ data }) => {
        props.history.push({
          pathname: '/auth/activate',
          state: {
            email: values.email,
            password: values.password
          }
        })
        disableLoading();
      })
      .catch((error) => {
        setSubmitting(false);
        setStatus(
          error?.response?.data?.message,
        )
        disableLoading();
      });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your details to create your account
        </p>
      </div>

      <form
                id="kt_login_signin_form"
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                onSubmit={formik.handleSubmit}
            >
                {/* begin: Alert */}
                {formik.status && (
                    <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                {/* end: Alert */}

                {/* begin: Fullname */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter first name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "fullname"
                        )}`}
                        name="first_name"
                        {...formik.getFieldProps("first_name")}
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.first_name}</div>
                        </div>
                    ) : null}
                </div>

                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Enter last name"
                        type="text"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "fullname"
                        )}`}
                        name="last_name"
                        {...formik.getFieldProps("last_name")}
                    />
                    {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.last_name}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Fullname */}

                {/* begin: Email */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        readOnly
                        disabled
                        placeholder="inviteduser@mail.com"
                        type="email"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "email"
                        )}`}
                        name="email"
                        {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.email}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Email */}

                {/* begin: telephone 
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Phone number"
                        type="tel"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "tel"
                        )}`}
                        name="tel"
                        {...formik.getFieldProps("tel")}
                    />
                    {formik.touched.tel && formik.errors.tel ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.tel}</div>
                        </div>
                    ) : null}
                </div>
                 end: telephone */}

                {/* begin: telephone */}
                {/* <div className="form-group fv-plugins-icon-container">
                    <IntlTelInput
                        onPhoneNumberChange={(isValid, number, country) => {
                            // setFieldValue(name, number);
                            updateData(number, country);

                            // Always invalid
                            console.log(isValid, number);
                            setIsFormValid(isValid);
                        }}
                        onSelectFlag={(number, country) => {
                            // setFieldValue(name, number);
                        }}
                        onPhoneNumberBlur={(isValid) => {
                            // setIsFormValid(isValid);
                        }}
                        // fieldId={name}
                        // fieldName={name}
                        format
                        formatOnInit

                        {...formik.getFieldProps("tel")}
                    />
                    {formik.touched.tel && formik.errors.tel ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.tel}</div>
                        </div>
                    ) : null}
                </div> */}
                {/* end: telephone */}

                <div className="form-group fv-plugins-icon-container">
                    <IntlTelInput
                        ref = {phoneRef}
                        preferredCountries={['ng', 'za', "us", "gb", 'gh']}
                        inputClassName={`form-control form-control-solid h-auto py-5  ${getInputClasses(
                            "tel"
                        )}`}
                        style = {{
                            width: '100%'
                        }}
                        format = {true}
                        onPhoneNumberFocus = {(isValid, number, obj) => {
                          var num = `+${obj?.dialCode}${number}`;
                          var isValidR = isValidPhoneNumber(num)
                          // console.log(isValidR)
                          setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                        }}
                        onPhoneNumberBlur= {(isValid, number, obj) => {
                          var num = `+${obj?.dialCode}${number}`;
                          var isValidR = isValidPhoneNumber(num)
                          // console.log(isValidR)
                          setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                        }}
                        onPhoneNumberChange = {(isValid, number, obj) => {
                            var num = `+${obj?.dialCode}${number}`;
                            var isValidR = isValidPhoneNumber(num)
                            setPhoneErr(isValidR ? "" : "Enter a valid phone number")
                            setPhoneNumber(number)
                            setData(obj)
                        }}
                        containerClassName="intl-tel-input"
                    />
                    {phone_err ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{phone_err}</div>
                        </div>
                    ) : null}
                </div>


                {/* begin: gender */}
                <div className="form-group fv-plugins-icon-container">

                    <FormControl fullWidth>
                        <select value={gender} className="form-control form-control-solid h-auto py-5 px-2" onChange={updateGender}>
                            <option value="">Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="others">Others</option>
                        </select>
                    </FormControl>
                </div>

                {/* begin: Password */}
                <div className="form-group fv-plugins-icon-container">
                    <input
                        placeholder="Password"
                        type="password"
                        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                            "password"
                        )}`}
                        name="password"
                        {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Password */}

                {/* begin: Terms and Conditions */}
                <div className="form-group">
                    <label className="checkbox">
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            className="m-1"
                            {...formik.getFieldProps("acceptTerms")}
                        />
                        <a href="https://sety.io/terms" target="_blank" className="mr-1" rel="noopener noreferrer">
                            I agree the Terms & Conditions
                        </a>
                        <span />
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.acceptTerms}</div>
                        </div>
                    ) : null}
                </div>
                {/* end: Terms and Conditions */}
                <div className="form-group d-flex flex-wrap flex-center">
                    <button
                        type="submit"
                        disabled={formik.isSubmitting || !formik.values.acceptTerms}
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                        <span>Submit</span>
                        {loading && <span className="ml-3 spinner spinner-white"></span>}
                    </button>

                    <Link to="/auth/login">
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                      >
                        Go to login
                      </button>
                    </Link>
                </div>
            </form>
            
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
