import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/incidents/Actions";
import { Header } from "./header";
import { FormView } from "./form";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export function Process({ id, show, onHide }) {

  const dispatch = useDispatch();
  const { loading, categories, dataForView, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.alert.actionsLoading || state.alert.listLoading,
      categories: state.alert.categories,
      dataForView: state.alert.dataForView,
      error_ser: state.alert.error_ser
    }),
    shallowEqual
  );

  const [image, set_image] = useState(null)
  const [error, set_error] = useState(null)





  useEffect(() => {
    if (dataForView) {
      dispatch(actions.fetchAllData('categories',  dataForView.business.id));
    }
  }, [dataForView])


  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);

  // server request for saving customer
  const saveCustomer = (e) => {
    var body = {};
    body['category'] = e.target.elements.category.value;
    dispatch(actions.addData('update_cat', body, id)).then((res) => {
      // console.log(res); return;
      onHide()
      dispatch(actions.fetchAllData('single', id))

    }).catch(() => {
      set_error("An error updating category");
    });
  };

  // const notify = () => toast("Wow so easy !");

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      // aria-labelledby="example-modal-sizes-title-lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Header id={id} />
      <FormView
        saveCustomer={saveCustomer}
        actionsLoading={loading}

        data={null}
        onHide={onHide}

        error={error}

        categories={categories}
        loading={loading}

      />
    </Modal>
  );
}
