import React, {useRef, useState} from "react";
import { Modal } from "react-bootstrap";

import {  Form, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import config from "../../../../../config";

export function FormView({
  saveCustomer,
  actionsLoading,
  address,
  onHide,
  setAd,
  error
}) {
  

  return (
    <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
{/* 
            {error &&
            <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
                <div class="alert-icon"><i class="flaticon-warning"></i></div>
                <div class="alert-text">{error}</div>
                <div class="alert-close">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true"><i class="ki ki-close"></i></span>
                    </button>
                </div>
            </div>} */}
            


              <div className="d-flex align-items-center justify-content-between">


              <Form style = {{flex: 2}} className="form form-label-right"
              id = "update-form"
              onSubmit={e => {
                e.preventDefault();
                // saveCustomer(e)
                }
              }
              >
           

        

           <Form.Group>
                <Form.Label>Location</Form.Label>
           
                  <GooglePlacesAutocomplete
                    apiKey={config.google_map_api_key}
                    apiOptions={{ language: 'en', region: 'NG' }} 
                    selectProps={{
                      value: address,
                      placeholder: 'Change address',
                      onChange: setAd
                    }}
                  />
                
                </Form.Group>
 
     

              </Form>


             
              </div>
            </Modal.Body>
            <Modal.Footer>
              <a onClick={onHide} class="btn btn-text-dark font-weight-bold mr-2">Cancel</a>
              <> </>
              <button
                form='update-form'
                type="submit"
                disabled = {actionsLoading}
                onClick={() => saveCustomer()}
                className="btn btn-primary btn-elevate"
              >
                Update
              </button>
            </Modal.Footer>
       
    </> 
    
  );
}
