import axios from "axios";
import config from "../../../../../config";

export const URL = config.URL;

// CREATE =>  POST: add a new data to the server
export function addNewData(type, data, id) {
  if (type === 'update_cat') {
    return axios.post(URL+'dangers/'+id+'/update-category', data);
  } else if (type === 'accept') {
    return axios.post(URL+'responders/accept-task', data);
  } else if (type === 'cancel') {
    return axios.post(URL+'responders/cancel-task', data);
  } else if (type === 'complete') {
    return axios.post(URL+'responders/complete-task', data);
  } else if (type === 'update_address') {
    return axios.put(URL+'dangers/'+id+'/update-address', data);
  } else if (type === 'incident') {
    return axios.post(URL+'dangers/responders', data);
  } 
  return axios.post(URL, { data });
}

// READ
export function getAllDatas() {
  return axios.get(URL);
}

export function getSataById(dataId) {
  return axios.get(`${URL}/${dataId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDatas(type, queryParams) {
  if (type === 'count_status') {
    return axios.get(`${URL}partners/${queryParams}/count-status`);
  } else if (type === 'active_danger') {
    return axios.get(`${URL}responders/${queryParams}/dangers?status=active`);
  } else if (type === 'pending_danger') {
    return axios.get(`${URL}responders/${queryParams}/dangers?status=pending`);
    // return axios.get(`${URL}dangers${queryParams}`);
  } else if (type === 'single') {
    return axios.get(`${URL}dangers/${queryParams}`);
  } else if (type === 'categories') {
    return axios.get(`${URL}businesses/${queryParams}/categories`);
  } else if (type === 'business') {
    return axios.get(`${URL}businesses`);
  } else if (type === 'agent_loc') {
    return axios.get(`${URL}locations/agents/${queryParams}`);
  } else if (type === 'partners') {
    return axios.get(`${URL}partners/categories/${queryParams}`);
  } else if (type === 'partner_user') {
    return axios.get(`${URL}partners/users/${queryParams}`);
  } 
}

// UPDATE => PUT: update the data on the server
export function updateData(data) {
  return axios.put(`${URL}/${data.id}`, { data });
}

// UPDATE Status
export function updateStatusForDatas(ids, status) {
  return axios.post(`${URL}/updateStatusFordatas`, {
    ids,
    status
  });
}

// DELETE => delete the data from the server
export function deleteData(dataId) {
  return axios.delete(`${URL}/${dataId}`);
}

// DELETE datas by ids
export function deleteDatas(ids) {
  return axios.post(`${URL}/deletedatas`, { ids });
}
