import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/CallCenters/Actions";
import { Header } from "./header";
import { FormView } from "./form";
import Geocode from "react-geocode";
import config from "../../../../../config";


Geocode.setApiKey(config.google_map_api_key);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");


export function New({ id, show, onHide }) {


  const dispatch = useDispatch();
  const { actionsLoading, categories, business, org, error_ser } = useSelector(
    (state) => ({
      actionsLoading: state.accounts.actionsLoading,
      org: state.auth.organization,
      error_ser: state.accounts.error,
      categories: state.alert.categories,
      business: state.alert.business
    }),
    shallowEqual
  );

  const [image, set_image] = useState(null)
  const [image_link, set_image_link] = useState(null)
  const [error, set_error] = useState(null)
  const [address, set_address] = useState(null)

  const [latitude, setLat] = useState(null);
  const [longitude, setLng] = useState(null);
  const [load, set_load] = useState(false);


  useEffect(() => {
    // if (!categories || categories.length === 0) {
    //   dispatch(actions.fetchAllData('categories', '?active=true'));
    // }

    if (!business || business.length === 0) {
      dispatch(actions.fetchAllData('business', ''));
    }
  }, [])



  function setAd(add) {
    set_address(add);
    set_load(true);


    Geocode.fromAddress(add.label).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLat(lat)
        setLng(lng)
        set_load(false);

      },
      (error) => {
        set_load(false);
        console.error(error);
      }
    );
  }

  useEffect(() => {
    if (error_ser) {
      set_error(error_ser);
    }
  }, [error_ser]);



  // server request for saving customer
  const saveCustomer = (e) => {

    set_error(null);

    var body = {};
    var loc = {};
    loc['latitude'] = latitude;
    loc['longitude'] = longitude;
    const metaData = {
      "car_make": e.target.elements.car_make.value,
      "car_model": e.target.elements.car_model.value,
      "plate_number": e.target.elements.plate_number.value,
      "reporter": e.target.elements.reporter.value,
      "car_color": e.target.elements.car_colo.valuer
    }

    body['location'] = loc; 
    body['victim_name'] = e.target.elements.victim_name.value;
    body['more_info'] = e.target.elements.more_info.value;
    body['input_address'] = address;
    body['business'] = e.target.elements.business.value;
    body['category'] = e.target.elements.category.value;
    body['report_channel'] = 'phone_call';
    body['phone_number'] = e.target.elements.phone_number.value;
    body['meta'] = metaData
    




      dispatch(actions.addData('incident', body)).then((res) => {
        // console.log(res);
        if (res) {
          onHide()
        } else {
          set_error("An error occurred while creating this incident"); return;
        }
      }).catch(() => {
        set_error("An error occurred while creating this incident"); return;
      });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Header id={id} />
      <FormView
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading || load}
        data={null}
        business={business}
        onHide={onHide}
        categories={categories}
        error={error}
        image_link={image_link}
        image={image}
        setAd={setAd}
        address={address}
      />
    </Modal>
  );
}
